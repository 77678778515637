import React, { useState, useEffect } from 'react';
import './App.css';
import addNotification from "react-push-notification";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import Timer from "./Timer.js"
import { Notifications } from 'react-push-notification';


const cities = [
  'Toronto', 'Montreal', 'Vancouver', 'Calgary', 'Edmonton',
  'Ottawa', 'Quebec City', 'Winnipeg', 'Hamilton', 'Kitchener',
  'London', 'Victoria', 'Halifax', 'Oshawa', 'Windsor',
  'Saskatoon', 'Regina', 'Sherbrooke', 'Barrie'
];

async function getCurrentTemperature(city) {
  const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?q=${city},ca&appid=911acdd288feb31418bf99d507d7c050`);
  const temperature = Math.round(response.data.main.temp);
  return temperature - 273;
}

const today = new Date();
const month = today.getMonth();
const date = today.getDate();
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


function App() {
  const [city, setCity] = useState(cities[Math.floor(Math.random() * cities.length)]);
  const [temperature, setTemperature] = useState(0);
  const [count, setCount] = useState(0);
  const [showDateModal, setShowDateModal] = useState(true);

  function successNotification() {
    addNotification({
        title: "Success",
        subtitle: "GOOD JOB",
        message: "you have successfully done a shot",
        theme: "light",
        closeButton: "X",
        backgroundTop: "green",
        backgroundBottom: "yellowgreen",
    });
  }

  function winNotification() {
    addNotification({
        title: "WINNER",
        subtitle: "YOU ARE DRUNK",
        message: "Congratulations, you have achieved the prestigious win",
        theme: "light",
        closeButton: "X",
        backgroundTop: "yellow",
        backgroundBottom: "gold",
    });
  }

  useEffect(() => {
    getCurrentTemperature(city).then(temp => {
      setTemperature(temp);
      setCount(Math.abs(temp)+date);
    });
  }, [city]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount(prevCount => prevCount + 1);
    },10000); 

    return () => clearInterval(intervalId);
  }, []);


  const incrementCount = () => {
    setCount(count - 1);
  }
    

  function buttonOnClick(e) {
    if (count > 1) {
      incrementCount();
      e.preventDefault();
      successNotification();
    }
    else if (count === 1) {
      winNotification();
      incrementCount();
    }
    else {
      winNotification();
    }
  }

  const resetCount = () => {
    setCount(Math.abs(temperature)+date);
  };

  const changeCity = () => {
    const newCity = cities[Math.floor(Math.random() * cities.length)];
    setCity(newCity);
  };

  const hideDateModal = () => {
    setShowDateModal(false)
  };


  return (
    <div className={`App ${city}`}>
      <Notifications />
      <header className="App-header">

      <Modal show={showDateModal}>
        <div className="popup">
          <p className='popup-text'>Today's date is {monthNames[month]} {date}</p>
          <p className='popup-text'>The temperature in {city} is {temperature}°C</p>
            <p className='popup-text'>So take {date} + {Math.abs(temperature)} shots!</p>
          <div className='button-container'>
            <button className='btn' onClick={hideDateModal}>Close</button>
          </div>
          </div>
      </Modal>


        <h1 className='App-header2'>
        🍺 Shot Counter 🍺
        </h1>

        <h1 className='App-counter' onClick={() => window.location.href = 'https://youtu.be/dQw4w9WgXcQ'}>
          {count}
        </h1>

        <div className="button-container">
          <button className="btn" onClick={buttonOnClick}>
            <i className="icon">➕</i>
            SHOT!
          </button>
          <button className="btn" onClick={resetCount}>
            <i className="icon">🔄</i>
            Reset
          </button>
          <button className="btn" onClick={changeCity}>
            <i className="icon">🔄</i>
            Change City
          </button>
        </div>
        <div className='timer-container' style={{ marginBottom: '40px' }}> 
          <Timer/>
        </div>
        <div className='text-container'>
          The current temperature in {city} is {temperature}°C.
        </div>
      </header>
    </div>
  );
}

export default App;
